import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Location } from '@angular/common';
import { LoadingController, PopoverController, ToastController } from "@ionic/angular";
import { Formats } from "@enums/index";

@Injectable({
  providedIn: 'root',
})

export class UtilsFunctions {

  loadingPage: any;

  constructor(
    private loadingController: LoadingController,
    private toastController: ToastController,
    private location: Location
  ) {

  }

  async presentToast(message: string, color: string, duration: number = 1500, position: 'top' | 'middle' | 'bottom' = 'top') {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      color: color,
      position: position,
    });

    await toast.present();
    return toast;
  }

  async presentLoading() {
    this.loadingPage = await this.loadingController.create({
      message: 'Cargando...',
      duration: 100000
    });
    await this.loadingPage.present();
  }

  async stopLoading() {
    await this.loadingPage.dismiss();
  }

  goBack() {
    this.location.back();
  }

  getAllowedPermissions(rolesList: any) {
    let allowedPermissions: any = [];
    rolesList.map((item: any) => {
      item.role.permissions.map((p: any) => {
        allowedPermissions.push(p.permission.name);
      });
    });
    return allowedPermissions;
  }

  toBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result ? reader.result.toString() : '');
      reader.onerror = (error) => reject(error);
    });
  }

  manageCsVOrExcelFile(data: any, fileName: string, type: string) {

    if (type === 'file') type = Formats.XLS;
    if (type === 'file/csv') type = Formats.CSV;

    const blob = new Blob([data], { type: type === Formats.CSV ? 'text/csv' : 'application/octet-stream' });
    const filePath = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = filePath;
    downloadLink.setAttribute('download', fileName + `.${type}`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  isJson(item: any) {
    let value = typeof item !== "string" ? JSON.stringify(item) : item;
    try {
      value = JSON.parse(value);
    } catch (e) {
      return false;
    }

    return typeof value === "object" && value !== null;
  }
}

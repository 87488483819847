import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpComplement } from '@shared/functions';
import { catchError, map, Observable, of } from 'rxjs';
import { MessagesAlerts } from 'src/common/functions/messages-alerts';
import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { AuthService } from 'src/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ValidateAuthValidateAccessGuard implements CanActivate {

  API_URI: string = environment.API_URL

  constructor(
    private authService: AuthService,
    public router: Router,
    private http: HttpClient,
    private headerComplement: HttpComplement,
    private messagesAlerts: MessagesAlerts,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {

    if (localStorage.getItem('token') != null) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }


}

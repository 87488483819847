import { Injectable } from "@angular/core";
import { AlertController, IonicSafeString, ToastController } from "@ionic/angular";

@Injectable({
    providedIn: 'root',
})
export class MessagesAlerts {
    private toastMsgs: string[] = [];
    constructor(
        public toastCtrl: ToastController,
        private alertController: AlertController,
    ) { }
    /**
    * Opens a toast with the specified message, color, duration, and position.
    *
    * @param {string | string[]} message - The message to be displayed in the toast.
    * @param {string} color - The color of the toast.
    * @param {number} duration - Optional. The duration of the toast in milliseconds.
    * @param {'top'} position - Optional. The position of the toast on the screen.
    * @returns {Promise<void>} A promise that resolves when the toast is presented.
    */
    async openToast(message: string | string[], color: string, duration?: 3000, position?: 'top') {
        !Array.isArray(message) ? this.openSingleToast(message, color, duration, position) : this.openMultipleToast(message, color, duration, position);
    }

    async openAlert(message: string) {
        const alert = await this.alertController.create({
            message: message,
            buttons: ['Aceptar'],
            animated: true,
        })
        await alert.present();
    }

    async openSingleToast(message: string, color: string, duration?: 3000, position?: 'top') {
        const toast = await this.toastCtrl.create({
            message: message,
            color: color,
            duration: duration ? duration : 3000,
            position: position,
        });
        toast.present();
    }

    async openMultipleToast(message: string[], color: string, duration?: 3000, position?: 'top') {
        this.toastMsgs = message;
        const toast = await this.toastCtrl.create({
            message: this.toastMsgs.toString().split(",").join(", "),
            color: color,
            duration: duration ? duration : 3000,
            position: position
        });
        toast.present();
        toast.onDidDismiss().then(() => {
            this.toastMsgs = [];
        });
    }
}
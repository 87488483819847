import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { MessagesAlerts } from 'src/common/functions/messages-alerts';
import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { AuthService } from 'src/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CheckAuthenticatedGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    public router: Router,
    private messagesAlerts: MessagesAlerts,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (localStorage.getItem('token') != null) {
      return this.authService.isAuthenticated();
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}

import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})

export class ModeLigthDark {
    darkMode = false;
    private darkMode$: BehaviorSubject<boolean>;

    constructor() {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        this.darkMode = prefersDark.matches;
        if (this.darkMode) document.body.classList.toggle('dark');
        this.darkMode$ = new BehaviorSubject<boolean>(this.darkMode);
    }

    getMode() {
        return this.darkMode;
    }

    changeMode() {
        this.darkMode = !this.darkMode;
        document.body.classList.toggle('dark');
        this.setDarkMode(this.darkMode);
    }

    private setDarkMode(darkMode: boolean) { this.darkMode$.next(darkMode); }
    getDarkMode(): Observable<boolean> { return this.darkMode$.asObservable(); }
}
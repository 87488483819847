export enum Permissions {
    CREATE = 'Create',
    READ = 'Read',
    UPDATE = "Update",
    DELETE = "Delete",
    DOWNLOAD = "Read",
  }

  export enum ModulesName {
    user = 'Admin Usuarios',
    role = 'Roles y permisos',
    client = 'Clientes',
    report = 'Reportes',
    plan = 'Planes y servicios',
    consultancy = 'Consultoria',
    payment_plans = 'Pago de planes',
    request = 'Registro solicitudes',
  }
  